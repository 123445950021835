import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import Icon from '../src/helpers/icon';
import pysIcons from '../src/icons/pys-icons';
import { Freya } from '../src/components/Freya/Freya';
import Pawlink from '../src/components/PawLink/PawLink';
import Buttons from '../src/components/Buttons/Buttons';
import SEO from '../src/helpers/seo';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import './plan_your_stay.scss';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query plan_your_state($locale: String!) {
    allPlanStaySeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allPlanStayTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          sub_title
          description
        }
      }
    }
    allPlanStayImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPlanStayImageMobileBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPlanStayBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allPlanStayDescriptionBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allPysCards(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttons {
            cta
            ctaText
            color
            size
          }
        }
      }
    }
    allPlanStayRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;

const initial = {
  isFamily: false,
  conAmigos: false,
};
const initial2 = {
  isUnDia: false,
  escapada: false,
  vacaciones: false,
};
class PlanStay extends Component {
  state = {
    data: {
      isFamily: false,
      conAmigos: false,
      isUnDia: false,
      escapada: false,
      vacaciones: false,
    },
    seeMore: false,
  };

  calculateUrls = () => {
    let parameter1 = '';
    let parameter2 = '';

    const { isFamily, conAmigos, isUnDia, escapada, vacaciones } = this.state.data;

    if ((isFamily || conAmigos) && (isUnDia || escapada || vacaciones)) {
      if (isFamily === true) {
        parameter1 = tt('familia', this.props.pageContext.locale);
      }
      if (conAmigos === true) {
        parameter1 = tt('amigos', this.props.pageContext.locale);
      }
      if (isUnDia === true) {
        parameter2 = tt('1-dia', this.props.pageContext.locale);
      }
      if (escapada === true) {
        parameter2 = tt('escapada', this.props.pageContext.locale);
      }
      if (vacaciones === true) {
        parameter2 = tt('vacaciones', this.props.pageContext.locale);
      }
      return `${
        this.props.pageContext.url
      }/${parameter1.toLowerCase()}-${parameter2.toLowerCase()}`;
    }
  };

  isDisabled = () => {
    const { isFamily, conAmigos, isUnDia, escapada, vacaciones } = this.state.data;
    const firstRow = {
      isFamily,
      conAmigos,
    };
    const secondRow = {
      isUnDia,
      escapada,
      vacaciones,
    };

    if (Object.values(firstRow).includes(true) && Object.values(secondRow).includes(true)) {
      return '';
    }
    return 'disabled';
  };

  selectedButtonWith = e => {
    if (e === 'enFamilia') {
      this.setState({
        data: { ...this.state.data, ...initial, isFamily: true },
      });
    }
    if (e === 'conAmigos') {
      this.setState({
        data: { ...this.state.data, ...initial, conAmigos: true },
      });
    }
    if (e === 'unDia') {
      this.setState({
        data: { ...this.state.data, ...initial2, isUnDia: true },
      });
    }
    if (e === 'escapada') {
      this.setState({
        data: { ...this.state.data, ...initial2, escapada: true },
      });
    }
    if (e === 'vacaciones') {
      this.setState({
        data: { ...this.state.data, ...initial2, vacaciones: true },
      });
    }
  };

  showMore = () => {
    this.setState({
      seeMore: !this.state.seeMore,
    });
  };

  render() {
    const { isFamily, conAmigos, isUnDia, escapada, vacaciones } = this.state.data;

    const heimdallData = {
      name: this.props.data.allPlanStayTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allPlanStayTitleBlock.edges[0].node.sub_title,
      image: {
        url: this.props.data.allPlanStayImageBlock.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allPlanStayImageBlock.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.allPlanStayImageMobileBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const h3Data = {
      title: tt(
        'EN FAMILIA O CON AMIGOS, DESCUBRE OFERTAS Y DESCUENTOS EXCLUSIVOS PARA GRUPOS',
        this.props.pageContext.locale
      ),
    };
    const h3Data2 = {
      title: this.props.data.allPlanStayDescriptionBlock.edges[0].node.title,
    };

    const h4Data = {
      title: tt('1. ¿Con quién vas a viajar?', this.props.pageContext.locale),
      title2: tt('2. ¿Cuántos días vas a estar?', this.props.pageContext.locale),
    };

    const buttonText = {
      cta: tt('DESCUBRE TODO LO QUE PUEDES HACER', this.props.pageContext.locale),
    };

    const freyaData = {
      cards: this.props.data.allPysCards.edges.map(promotion => ({
        image: promotion.node.localImage.childImageSharp.fluid,
        altImage: promotion.node.alt,
        title: promotion.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: promotion.node.buttons.cta,
          size: 'alone',
          color: 'white',
          ctaText: promotion.node.buttons.ctaText,
        },
      })),
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allPlanStaySeoBlock.edges[0].node._0.title}
          description={this.props.data.allPlanStaySeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="politica-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allPlanStayBreadcrumbBlock.edges[0].node.name}
              />
              <div className="pys-container">
                <H3 data={h3Data} />
                {this.state.seeMore && (
                  <div
                    className="pys-description"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.allPlanStayTitleBlock.edges[0].node.description,
                    }}
                  />
                )}
                {this.state.seeMore ? (
                  <div className="see-more-true" onClick={this.showMore}>
                    {tt('Leer menos', this.props.pageContext.locale)}
                  </div>
                ) : (
                  <div className="see-more-false" onClick={this.showMore}>
                    {tt('Leer más', this.props.pageContext.locale)}
                  </div>
                )}
                <H4 title={h4Data.title} />
                <div className="icon-container">
                  <div
                    className={`icon-full ${isFamily ? 'active' : ''}`}
                    onClick={() => this.selectedButtonWith('enFamilia')}
                  >
                    <div className="icon-single">
                      <Icon
                        icon="en-familia"
                        width="90"
                        height="20"
                        color={`${isFamily ? 'white' : '#5e5e5e'}`}
                        iconSet={pysIcons}
                      />
                    </div>
                    <div className="icon-text">
                      {tt('EN FAMILIA', this.props.pageContext.locale)}
                    </div>
                  </div>
                  <div
                    className={`icon-full ${conAmigos ? 'active' : ''}`}
                    onClick={() => this.selectedButtonWith('conAmigos')}
                  >
                    <div className="icon-single">
                      <Icon
                        icon="con-amigos"
                        width="90"
                        height="20"
                        color={`${conAmigos ? 'white' : '#5e5e5e'}`}
                        iconSet={pysIcons}
                      />
                    </div>
                    <div className="icon-text">
                      {tt('CON AMIGOS', this.props.pageContext.locale)}
                    </div>
                  </div>
                </div>
                <H4 title={h4Data.title2} />
                <div className="icon-container-three">
                  <div
                    className={`icon-full ${isUnDia ? 'active' : ''}`}
                    onClick={() => this.selectedButtonWith('unDia')}
                  >
                    <div className="icon-single">
                      <Icon
                        icon="escapada-visita-1-dia"
                        width="112"
                        height="32"
                        color={`${isUnDia ? 'white' : '#5e5e5e'}`}
                        iconSet={pysIcons}
                      />
                    </div>
                    <div className="icon-text">
                      {tt('VISITA DE 1 DÍA', this.props.pageContext.locale)}
                    </div>
                  </div>
                  <div
                    className={`icon-full ${escapada ? 'active' : ''}`}
                    onClick={() => this.selectedButtonWith('escapada')}
                  >
                    <div className="icon-single">
                      <Icon
                        icon="escapada-visita-1-dia"
                        width="112"
                        height="32"
                        color={`${escapada ? 'white' : '#5e5e5e'}`}
                        iconSet={pysIcons}
                      />
                      <div className={`number-container ${escapada ? 'active' : ''}`}>+2</div>
                    </div>
                    <div className="icon-text">{tt('ESCAPADA', this.props.pageContext.locale)}</div>
                  </div>
                  <div
                    className={`icon-full ${vacaciones ? 'active' : ''}`}
                    onClick={() => this.selectedButtonWith('vacaciones')}
                  >
                    <div className="icon-single">
                      <Icon
                        icon="vacaciones"
                        width="130"
                        height="32"
                        color={`${vacaciones ? 'white' : '#5e5e5e'}`}
                        iconSet={pysIcons}
                      />
                      <div className={`number-container ${vacaciones ? 'active' : ''}`}>+3</div>
                    </div>
                    <div className="icon-text">
                      {tt('VACACIONES', this.props.pageContext.locale)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-container">
                <Buttons
                  color="orange"
                  size="alone"
                  exception={this.isDisabled()}
                  link={this.calculateUrls()}
                  text={buttonText.cta}
                />
              </div>
              <H3 data={h3Data2} />
              <div
                className="pys-description"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allPlanStayDescriptionBlock.edges[0].node.description,
                }}
              />
              <div className="pys-section-experience">
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PlanStay;
